<template>
  <div>
    <div v-if="allows_crud" class="row m-1">
      <b-button-group>
        <b-button
          @click="$bvModal.show(`modal-update-survey-${survey.id}`)"
          size="sm"
          >Editar Encuesta</b-button
        >
        <b-dropdown right text="Agregar" size="sm">
          <b-dropdown-item
            @click="$bvModal.show(`modal-create-likert-${survey.id}`)"
            >Escala de Likert</b-dropdown-item
          >
          <b-dropdown-divider></b-dropdown-divider>
          <b-dropdown-item
            @click="$bvModal.show(`modal-create-question-${survey.id}`)"
            >Pregunta de Selección</b-dropdown-item
          >
        </b-dropdown>
      </b-button-group>
    </div>
    <div>
      <template v-for="record in items">
        <div :key="record.id">
          <template v-if="record.item_type == 'LikertScale'">
            <LikertScale
              :LikertScale="record.item_object"
              :order="record.order"
              :allows_crud="allows_crud"
            ></LikertScale>
          </template>
          <template v-else-if="record.item_type == 'SurveyQuestion'">
            <SurveyQuestion
              :SurveyQuestion="record.item_object"
              :order="record.order"
              :allows_crud="allows_crud"
              :QuestionTypes="question_option_types"
            ></SurveyQuestion>
          </template>
        </div>
      </template>
    </div>

    <!-- Modals -->
    <b-modal
      :id="`modal-update-survey-${survey.id}`"
      title="Editar Encuesta"
      hide-footer
    >
      <SurveyForm :Survey="survey" @updated="slotUpdatedSurvey"></SurveyForm>
    </b-modal>
    <b-modal
      :id="`modal-create-likert-${survey.id}`"
      title="Crear Escala de Likert"
      hide-footer
    >
      <LikertScaleForm @created="slotCreatedLinkertScale"></LikertScaleForm>
    </b-modal>
    <b-modal
      :id="`modal-create-question-${survey.id}`"
      title="Crear Pregunta"
      hide-footer
      size="lg"
    >
      <SurveyQuestionForm @created="slotCreatedQuestion"></SurveyQuestionForm>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "Survey",
  components: {
    SurveyForm: () => import("./SurveyForm"),
    LikertScaleForm: () => import("./LikertScaleForm"),
    LikertScale: () => import("./LikertScale"),
    SurveyQuestion: () => import("./SurveyQuestion"),
    SurveyQuestionForm: () => import("./SurveyQuestionForm"),
  },
  props: {
    Survey: {
      type: Object,
      required: true,
    },
    allows_crud: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      survey: this.Survey,
      items: [],
      question_option_types: null,
    };
  },
  methods: {
    fetchItems() {
      this.$restful
        .Get(`/cideu/survey-items/?survey=${this.survey.id}`)
        .then((response) => {
          this.items = response;
        });
    },
    fetchQuestionTypes() {
      this.$restful.Get(`/cideu/question-types/`).then((response) => {
        this.question_option_types = response;
      });
    },
    slotUpdatedSurvey(survey) {
      this.survey = survey;
      this.$bvModal.hide(`modal-update-survey-${this.survey.id}`);
      this.$emit("updated", survey);
    },
    slotCreatedLinkertScale(likert_scale) {
      this.crateSurveyItemRecord(likert_scale);
      this.$bvModal.hide(`modal-create-likert-${this.survey.id}`);
    },
    slotCreatedQuestion(question) {
      this.crateSurveyItemRecord(question);
      this.$bvModal.hide(`modal-create-question-${this.survey.id}`);
    },
    crateSurveyItemRecord(item) {
      const payload = {
        survey: this.survey.id,
        item: item.id,
        order: this.items.length + 1,
      };
      this.$restful.Post(`/cideu/survey-items/`, payload).then((response) => {
        console.log(response);
        this.items.push(response);
      });
    },
  },
  created() {
    this.fetchItems();
    this.fetchQuestionTypes();
  },
};
</script>

<style>
</style>